import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import PostLink from "../components/post-link"

const styles={
  columns:{
    color:'yellow',
    //backgroundColor:'red'
  },
  column:{
    textAlign:'left',
    padding:20,
    margin:'auto',
    color:'blue',
  },
  __html: node =>({
    fontSize:'x-small',
    color: node.frontmatter.color?node.frontmatter.color:'orange',
    background: node.frontmatter.background?node.frontmatter.background:'orange',
    opacity:0.8
  })
}


const Page = ({  data: {allMarkdownRemark: { edges },},}) => {
  /*
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <div><PostLink key={edge.node.id} post={edge.node} /></div>)
  */  
  return(
    <Layout>
      <SEO title="Page two" />
      <div className='column columns' style={styles.columns}>
        {edges.map((ed, ix) => 
          <div className={ix===1?'column is-half':'column'} style={styles.column} >
            <h3>{ed.node.frontmatter.title }</h3>
            <div 
              className='__html' 
              style={styles.__html((ed.node))}          
              dangerouslySetInnerHTML={{ __html: ed.node.html }}
            />        
          </div>
        )}  
      </div>  
    </Layout>
  )  
}

export default Page

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          html
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            color
            background
          }
        }
      }
    }
  }
`

